
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as OM from '@/model';

@Options({
    components: {
    }
})
export default class ExhibitionCard extends Vue {

    @Prop() exhibition: OM.ExhibitionListVm;

    created() {
    }
}
