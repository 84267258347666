
import { Options, Vue } from 'vue-class-component';
import { ExhibitionClient, StaffClient } from '@/services/services';
import Share from '@/components/share.vue';
import GiveFeedback from '@/components/giveFeedback.vue';
import InsertCodeOverlay from '@/components/insertCodeOverlay.vue';
import * as OM from '@/model';
import { LoginServices } from '@/services/LoginServices';
import ExhibitionCard from '../components/exhibitionCard.vue';
import insertRoomName from '../modals/insertRoomNameModal.vue';

@Options({
    components: {
        Share,
        GiveFeedback,
        InsertCodeOverlay,
        ExhibitionCard
    }
})
export default class Exhibitions extends Vue {

    exhibitions: OM.ExhibitionListVm[] = [];
    profile: OM.Staff = new OM.Staff();

    created() {
        Promise.all([
            ExhibitionClient.getAllByAssignedExhibitions(),
            StaffClient.getProfile()
        ])
        .then( xs => {
            this.exhibitions = xs[0];
            this.profile = xs[1];
        })
    }

    exhibitionClicked(exh: OM.ExhibitionListVm){
        this.$opModal.show(insertRoomName, {
            exhibition: exh
        })
    }

   logout() {
        LoginServices.logout()
        .then(x => {
            this.$router.replace('/');
        })
    }
}
